import { useTranslation } from 'react-i18next';
import { Button } from '~/components/common/buttons/Button';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import { useUser } from '~/modules/useUser';
import GuestLinks from './GuestLinks';
import HostLinks from './HostLinks';
import { useLanguageParam } from '~/config/i18n';

const AccountLinks = () => {
  const { t } = useTranslation();
  const clientStore = useUser();

  const locale = useLanguageParam();

  return (
    <>
      <a
        className="px-4 py-6 d-flex align-items-center text-decoration-none"
        data-turbo="false"
        href={
          clientStore?.isHost
            ? `/${locale}/host-dashboard/settings/profile`
            : `/${locale}/dashboard/profile/guest/details`
        }
      >
        <Button
          extraClasses="rounded-pill bg-grey-700 me-3"
          size="xl"
          isIconButton={true}
          variant="primary"
        >
          <span className="mt-1 fo-title-sm"> {clientStore?.initials?.charAt(0)}</span>
        </Button>
        <div>
          <div className="fo-display-5 -medium mb-1">{clientStore?.firstName}</div>
          <div className="text-2 -regular color-grey-500">{t('header.account.dropdown.myDetails')}</div>
        </div>
        <SVGIcon
          iconType={'chevron-right'}
          width="40"
          height="40"
          className="fo-icon-md ms-auto color-grey-600"
        />
      </a>

      <div className="w-100 fo-border-bottom-md border-1 border-grey-200 my-2"></div>

      {clientStore?.isHost === false && <GuestLinks />}
      {clientStore?.isHost && <HostLinks />}
    </>
  );
};

export default AccountLinks;
