import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import GenericModal from '~/components/common/GenericModal';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import { Link } from '@remix-run/react';
import mixpanelTrack from '~/hooks/useMixPanel';

declare var bootstrap: any;

const CompanyModal = () => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <GenericModal
      open={true}
      title={t('navbar.user.visitor.company.drawer.title')}
      modalRef={modalRef}
      extraClasses="modal-md"
      id="companyModal"
    >
      <div className="modal-body">
        <div className="list-group pt-4 pb-3 d-flex flex-column align-items-start">
          <Link
            to={t('global.linkPaths.aboutUs')}
            className="cursor-pointer py-3 list-group-item list-group-item-action d-flex align-items-center"
            onClick={() => {
              const signInModalInstance = bootstrap.Modal.getInstance(modalRef.current);
              signInModalInstance?.hide();
              mixpanelTrack('0.company_about_us');
            }}
          >
            <div className="text-1 -medium text-nowrap flex-fill">{t('navbar.user.visitor.company.menu.aboutUs')}</div>
            <SVGIcon
              iconType={'long-arrow-right'}
              width={'24'}
              height={'24'}
              fill={'#706D78'}
            />
          </Link>

          <Link
            to={t('global.linkPaths.blog')}
            reloadDocument={true}
            className="cursor-pointer py-3 list-group-item list-group-item-action d-flex align-items-center"
            onClick={() => {
              const signInModalInstance = bootstrap.Modal.getInstance(modalRef.current);
              signInModalInstance?.hide();
              mixpanelTrack('0.company_blog');
            }}
          >
            <div className="text-1 -medium text-nowrap flex-fill">{t('navbar.user.visitor.company.menu.blog')}</div>
            <SVGIcon
              iconType={'long-arrow-right'}
              width={'24'}
              height={'24'}
              fill={'#706D78'}
            />
          </Link>
        </div>
      </div>
    </GenericModal>
  );
};

export default CompanyModal;
