import { Link } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import { useLanguageParam } from '~/config/i18n';
import mixpanelTrack from '~/hooks/useMixPanel';

const VisitorButtons = () => {
  const { t } = useTranslation();
  const lang = useLanguageParam();

  return (
    <>
      <div className="flex-basis-100 d-flex flex-column align-items-center">
        <Link
          to={`/${lang}/signup/host`}
          rel="noreferrer"
          className="bottom-nav-button"
          onClick={() => {
            mixpanelTrack('0.list_your_office_nav');
          }}
        >
          <button className="d-flex flex-column align-items-center color-grey-600 text-decoration-none mx-auto px-0">
            <SVGIcon
              iconType={'building'}
              fill="#B8B6BC"
              height="24px"
              width="24px"
            />
            <div className="overline -medium mt-1">{t('global.btns.listMyOffice')}</div>
          </button>
        </Link>
      </div>

      <div className="flex-basis-100 d-flex flex-column align-items-center">
        <button
          data-form-name="Company"
          className="bottom-nav-button d-flex flex-column align-items-center color-grey-600 text-decoration-none mx-auto px-0"
          data-bs-toggle="modal"
          data-bs-target="#companyModal"
          onClick={() => {
            mixpanelTrack('0.search_help');
          }}
        >
          <SVGIcon
            iconType={'menu-2'}
            fill="#B8B6BC"
            height="24px"
            width="24px"
          />
          <div className="overline -medium mt-1">{t('navbar.user.visitor.company.label')}</div>
        </button>
      </div>

      <div className="flex-basis-100 d-flex flex-column align-items-center">
        <button
          data-controller="google-analytics"
          data-action="google-analytics#formEvent"
          data-google-analytics-target="source"
          data-event-type="form_complete"
          data-form-name="Sign In"
          className="bottom-nav-button d-flex flex-column align-items-center color-grey-600 text-decoration-none mx-auto mw-auto px-0"
          data-bs-toggle="modal"
          data-bs-target="#signInModal"
          onClick={() => {
            mixpanelTrack('0.call_us');
            // TODO: google-analytics event, clean up DOM attributes
          }}
        >
          <SVGIcon
            iconType={'user'}
            fill="#B8B6BC"
            height="24px"
            width="24px"
          />
          <div className="overline -medium mt-1">{t('navbar.user.visitor.signIn')}</div>
        </button>
      </div>
    </>
  );
};

export default VisitorButtons;
