import { useEffect, useRef, useCallback } from 'react';

declare global {
  interface Window {
    bootstrap?: any;
  }
}

type ModalIdentifier = React.RefObject<HTMLElement> | string;

//identifier can be a ref or the id of the modal
export const useBootstrapModal = (identifier: ModalIdentifier) => {
  const modalInstance = useRef<any>(null);

  const getModalElement = useCallback(() => {
    if (typeof identifier === 'string') {
      return document.getElementById(identifier);
    } else {
      return identifier.current;
    }
  }, [identifier]);

  const initModal = useCallback(() => {
    if (typeof window === 'undefined' || !window.bootstrap) return;

    const modalElement = getModalElement();
    if (!modalElement) return;

    modalInstance.current = window.bootstrap.Modal.getOrCreateInstance(modalElement);
  }, [getModalElement]);

  useEffect(() => {
    initModal();
  }, [initModal]);

  const showModal = useCallback(() => {
    if (modalInstance.current) {
      modalInstance.current.show();
    } else {
      console.warn('Modal instance not available. Initializing...');
      initModal();
      setTimeout(() => modalInstance.current?.show(), 200);
    }
  }, [initModal]);

  const hideModal = useCallback(() => {
    if (modalInstance.current) {
      modalInstance.current.hide();
    } else {
      console.warn('Modal instance not available. Initializing...');
      initModal();
      setTimeout(() => modalInstance.current?.hide(), 200);
    }
  }, [initModal]);

  return { showModal, hideModal };
};
