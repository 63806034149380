import { TFunction } from 'i18next';

//list of search params that should persist when doing a new search
export const PERSIST_SEARCH_PARAMS = [
  'guestFilter',
  'officeTypeFilter',
  'priceFilter',
  'surfaceFilter',
  'transportLines',
  'officeLayoutFilter',
  'discountPresent',
  'noDepositRequired',
  'accessTypeFilter',
  'rentalType',
  'duration',
  'topPicksOnly',
  'servicesFilter',
];

export function getPersistedSearchParams(searchParams: URLSearchParams) {
  const newSearchParams = new URLSearchParams();

  for (const key of searchParams.keys()) {
    if (PERSIST_SEARCH_PARAMS.some((persistKey) => key.includes(persistKey))) {
      const value = searchParams.get(key);
      if (value !== null) {
        newSearchParams.set(key, value);
      }
    }
  }

  return newSearchParams.toString();
}

export const getMetaContent = (t: TFunction, locationDescription: string, pageSource: string | undefined) => {
  switch (pageSource) {
    case 'offices-for-rent':
      return {
        title: t('head.title.searchOfficesForRent') + ' ' + locationDescription,
        description: t('head.metaDescription.searchOfficesForRent') + ' ' + locationDescription,
      };
    case 'flexible-offices':
      return {
        title: t('head.title.searchFlexibleOffices') + ' ' + locationDescription,
        description: t('head.metaDescription.searchFlexibleOffices') + ' ' + locationDescription,
      };
    case 'offices':
      return {
        title: t('head.title.searchOffices') + ' ' + locationDescription,
        description: t('head.metaDescription.searchOffices') + ' ' + locationDescription,
      };
    default:
      return {
        title: t('head.title.searchDefault') + ' ' + locationDescription,
        description: t('head.metaDescription.searchDefault') + ' ' + locationDescription,
      };
  }
};
