import { useTranslation } from 'react-i18next';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import AccountModalBtn from './AccountModalBtn';
import { useLanguageParam } from '~/config/i18n';
import { NavLink } from '@remix-run/react';

const GuestButtons = () => {
  const { t } = useTranslation();
  const locale = useLanguageParam();

  return (
    <>
      <div className="flex-basis-100 d-flex flex-row align-items-center">
        <NavLink
          className="bottom-nav-button d-flex flex-column align-items-center color-grey-300 text-decoration-none mx-auto px-0"
          to={`/${locale}/favorites`}
        >
          <SVGIcon iconType={'heart'} />
          <div className="overline">{t('header.favorites.label')}</div>
        </NavLink>
      </div>

      <div className="flex-basis-100 d-flex flex-row align-items-center">
        <a
          className="bottom-nav-button d-flex flex-column align-items-center color-grey-300 text-decoration-none mx-auto px-0"
          href={`/${locale}/dashboard/conversations`}
        >
          <SVGIcon iconType={'inbox'} />
          <div className="overline">{t('header.myRequests.label')}</div>
        </a>
      </div>

      <div className="flex-basis-100 d-flex flex-row align-items-center">
        <div className="bottom-nav-button mx-auto px-0">
          <AccountModalBtn />
        </div>
      </div>
    </>
  );
};

export default GuestButtons;
