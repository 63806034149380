import { Children, ReactElement, ReactNode } from 'react';

export type DropdownAlignment = 'left' | 'right';

interface DropdownProps {
  children: ReactNode;
  dropdownAlignment?: DropdownAlignment;
  dropdownAlignmentLg?: DropdownAlignment;
  minWidth?: string;
}

const Dropdown = ({
  children,
  dropdownAlignment = 'right',
  dropdownAlignmentLg = 'right',
  minWidth = undefined,
}: DropdownProps) => {
  const childrenArray = Children.toArray(children);
  const toggle = childrenArray[0] as ReactElement;
  const menu = childrenArray[1];

  return (
    <>
      <div className="dropdown">
        {toggle}
        <div
          className={`dropdown-menu mt-1 
                      ${dropdownAlignment == 'left' && 'dropdown-menu-start'}
                      ${dropdownAlignment == 'right' && 'dropdown-menu-end'}
                      ${dropdownAlignmentLg == 'left' && 'dropdown-menu-lg-start'}
                      ${dropdownAlignmentLg == 'right' && 'dropdown-menu-lg-end'}
                      `}
          style={{ minWidth: minWidth }}
        >
          {menu}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
