import SVGIcon from '~/components/ui/Icons/SVGIcon';
import type { PlaceObjectV2 } from '~/types/searchTypes';

const LocationItem = ({
  location,
  handleSelectPlace,
  isSelected = false,
}: {
  location: PlaceObjectV2;
  handleSelectPlace: (p: PlaceObjectV2) => void;
  isSelected: boolean;
}) => {
  const parts = location?.highlightDescription
    ? location.highlightDescription.split(',')
    : location?.description
      ? location.description.split(',')
      : [];
  const lastPart = parts.pop();
  return (
    <li
      key={location.placeId}
      onClick={() => handleSelectPlace(location)}
      className={`py-3 list-group-item mx-n4 border-0 rounded-1 cursor-pointer ${
        isSelected ? 'bg-grey-100' : ''
      } d-flex align-items-center`}
    >
      <SVGIcon
        iconType={'pin'}
        className="me-3 flex-shrink-0"
      />
      <div className="text-1">
        <span dangerouslySetInnerHTML={{ __html: parts.join(', ') }} />
        {lastPart && (
          <span
            className="color-grey-300 -regular"
            dangerouslySetInnerHTML={{ __html: `${parts.length > 0 ? ', ' : ''}${lastPart}` }}
          />
        )}
      </div>
    </li>
  );
};
export default LocationItem;
