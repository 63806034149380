import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import GenericModal from '~/components/common/GenericModal';
import { useUser } from '~/modules/useUser';
import LoginForm from '~/components/layouts/navigation/LoginForm';
import { useBootstrapModal } from '~/hooks/useBootstrapModal';

const LoginModal = () => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  const { hideModal } = useBootstrapModal(modalRef);
  const clientData = useUser();

  function handleOnSuccess() {
    if (clientData) {
      hideModal();
    }
  }

  return (
    <GenericModal
      open={true}
      title={t('forms.logInForm.subtitle')}
      modalRef={modalRef}
      extraClasses="modal-md"
      id="signInModal"
    >
      <div className="modal-body">
        <LoginForm
          hideHeader={true}
          onSuccess={handleOnSuccess}
        />
      </div>
    </GenericModal>
  );
};

export default LoginModal;
