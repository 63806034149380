import { useFetcher } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import SVGIcon from '~/components/ui/Icons/SVGIcon';

const LogoutButton = () => {
  const fetcher = useFetcher();
  const { t } = useTranslation();
  return (
    <fetcher.Form
      method="post"
      action="/logout"
    >
      <button
        className="list-group-item list-group-item-action d-flex align-items-center my-2"
        type="submit"
        data-bs-dismiss="modal"
      >
        <SVGIcon
          iconType={'signout'}
          className="me-2"
        />
        <div className="-medium color-grey-900">{t('header.account.dropdown.signOut')}</div>
      </button>
    </fetcher.Form>
  );
};

export default LogoutButton;
