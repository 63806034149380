import { useTranslation } from 'react-i18next';
import { useUser } from '~/modules/useUser';

const AccountModalBtn = () => {
  const { t } = useTranslation();
  const clientStore = useUser();
  return (
    <div
      className="d-flex flex-column align-items-center color-grey-600 mx-auto"
      role="button"
      data-bs-toggle="modal"
      data-bs-target="#userAccountModal"
    >
      <div className="btn-icon btn-icon-primary btn-icon-sm rounded-pill">
        <span className="caption">{clientStore?.initials?.charAt(0)}</span>
      </div>
      <div className="overline">{t('navbar.user.myAccount.label')}</div>
    </div>
  );
};

export default AccountModalBtn;
