export function uuidFromMath() {
  const result = new Array(16);

  let r = 0;
  for (let i = 0; i < 16; i++) {
    if ((i & 0x03) === 0) {
      r = Math.random() * 0x100000000;
    }
    result[i] = (r >>> ((i & 0x03) << 3)) & 0xff;
  }

  return result.join('-');
}
