import { useCallback, useEffect, useState } from 'react';
import { useUser } from '~/modules/useUser';
import GuestButtons from './GuestButtons';
import HostButtons from './HostButtons';
import VisitorButtons from './VisitorButtons';

const BottomMenu = () => {
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const clientStore = useUser();

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setVisible(lastScrollY > currentScrollY || currentScrollY < 10);
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY, handleScroll]);

  return (
    <div className="d-md-none">
      <nav
        className="mobile-navbar fo-border-top-sm border-grey-200 fixed-bottom bg-white container-fluid d-flex justify-content-center"
        id="bottom-menu"
        style={{ transform: visible ? 'translateY(0)' : 'translateY(100%)', transition: 'transform 0.3s ease-in-out' }}
      >
        <div className="menu-buttons-container mx-auto">
          {!clientStore && <VisitorButtons />}
          {clientStore?.isHost === false && <GuestButtons />}
          {clientStore?.isHost && <HostButtons />}
        </div>
      </nav>
    </div>
  );
};

export default BottomMenu;
