import { useTranslation } from 'react-i18next';
import { Button } from '~/components/common/buttons/Button';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import { useUser } from '~/modules/useUser';
import AccountDropdown from './AccountDropdown';
import VisitorMenu from './VisitorMenu';
import { useLanguageParam } from '~/config/i18n';

const UserMenu = ({ extraClasses = '' }: { extraClasses?: string }) => {
  const { t } = useTranslation();
  const lang = useLanguageParam();
  const clientStore = useUser();

  return (
    <div className={`user-menu d-flex align-items-center ${extraClasses}`}>
      {clientStore === null ? (
        <VisitorMenu />
      ) : (
        <div className="d-none d-md-flex align-items-center">
          {clientStore?.isHost === false && (
            <Button
              variant="navPrimaryLightOutline"
              extraClasses="me-6"
              onClick={() => (location.href = `/${lang}/favorites`)}
            >
              <SVGIcon iconType="heart" />
              <span className="ms-2 text-nowrap">{t('header.favorites.label')}</span>
            </Button>
          )}
          {clientStore?.isHost && (
            <Button
              variant="navPrimaryLightOutline"
              extraClasses="me-6"
              onClick={() => (location.href = `/${lang}/dashboard/host/overview`)}
            >
              <SVGIcon iconType="building" />
              <span className="ms-2 text-nowrap">{t('navbar.user.myOffices.label')}</span>
            </Button>
          )}
          <Button
            variant="navPrimaryLightOutline"
            extraClasses="me-6"
            onClick={() => (location.href = `/${lang}/dashboard/conversations`)}
          >
            <SVGIcon iconType="inbox" />
            <span className="ms-2 text-nowrap">{t('header.myRequests.label')}</span>
          </Button>
          <AccountDropdown />
        </div>
      )}
    </div>
  );
};

export default UserMenu;
