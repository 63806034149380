import { IconType } from '~/components/ui/Icons/Icons';
import SVGIcon from '~/components/ui/Icons/SVGIcon';

type TProps = {
  extraClasses?: string;
  link: string;
  linkText: string;
  iconClass: IconType;
  newTab?: boolean;
};

const AccountLinkItem = ({ extraClasses, link, linkText, iconClass, newTab = false }: TProps) => {
  return (
    <a
      className={`list-group-item list-group-item-action d-flex align-items-center py-3 ${extraClasses}`}
      href={link}
      target={newTab ? '_blank' : '_self'}
      rel="noopener noreferrer"
    >
      <SVGIcon
        iconType={iconClass}
        className="me-2"
      />
      <div className="-medium color-grey-900">{linkText}</div>
    </a>
  );
};

export default AccountLinkItem;
