import { useNavigate, useSearchParams } from '@remix-run/react';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '~/api';
import { LOCATION_LIST_API } from '~/api/apiUrlsV2';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import mixpanelTrack from '~/hooks/useMixPanel';
import LocationItem from '../../pages/search/LocationItem';
import { uuidFromMath } from '../../utils/uuid';
import type { PlaceObjectV2 } from '~/types/searchTypes';
import { useLanguageParam } from '~/config/i18n';
import { Button } from '../buttons/Button';
import { QUICK_LINKS } from '~/components/layouts/footer/seoLinksData';
import { getPersistedSearchParams } from '~/components/pages/search/utils';
import { ButtonLink } from '../buttons/ButtonLink';

const secureToken = uuidFromMath();

let abortController: AbortController;

const SearchForm = ({
  onClose = () => {},
  onSuccess = () => {},
  extraClasses = '',
  isMobileView = false,
}: {
  onClose?: () => void;
  onSuccess?: (label: string) => void;
  extraClasses?: string;
  isMobileView?: boolean;
}) => {
  const lang = useLanguageParam();
  const navigate = useNavigate();
  const { t } = useTranslation();
  //update fo api with country and city and even if possible arrondissement

  const DEFAULT_DESTINATION = `/${lang}/${t('routes.offices')}/Paris`;

  const [selectedLocationIndex, setSelectedLocationIndex] = useState<null | number>(null);
  const [showError, setShowError] = useState(false);
  const [searchParams] = useSearchParams();

  const inputRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const [locations, setLocations] = useState<PlaceObjectV2[]>([]);

  const [searchPlace, setSearchPlace] = useState<PlaceObjectV2>({
    formattedAddress: '',
    placeId: searchParams.get('place.placeId') || '',
    description: searchParams.get('place.description') || '',
  });

  const [isInitialData, setIsInitialData] = useState(true);

  const showSuggestions = (locations.length === 0 && isInitialData) || searchPlace.description === '';

  useEffect(() => {
    inputRef.current?.focus();
    if (searchPlace.description !== '') fetchLocations(searchPlace.description, true);
    if (isMobileView) document.body.classList.add('overflow-hidden');
  }, []);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef]);

  const goToResult = useCallback(async () => {
    const persistedSearchParams = getPersistedSearchParams(searchParams);
    mixpanelTrack('C.search_true');

    if (['/fr', '/en'].includes(window.location.pathname)) {
      mixpanelTrack('C.search_true_hp');
    }
    let url = `/${lang}/search/`;
    if (searchPlace.city) url += `${searchPlace.city.toLowerCase()}/`;
    if (searchPlace.city && searchPlace.zipCode) url += `${searchPlace.zipCode}/`;
    if (searchPlace.city && searchPlace.zipCode && searchPlace.neighbourhood) url += `${searchPlace.neighbourhood}`;

    if (!searchPlace.city && (!searchPlace.description || !searchPlace.placeId)) {
      navigate(DEFAULT_DESTINATION);
    } else
      navigate(
        `${url}?place.description=${searchPlace.formattedAddress}&place.placeId=${searchPlace.placeId}&${persistedSearchParams}`,
      );
    onSuccess(searchPlace.formattedAddress);
  }, [lang, searchPlace, onSuccess, navigate, DEFAULT_DESTINATION]);

  const getLocationsList = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsInitialData(false);
    setShowError(false);
    setSearchPlace((prev) => ({
      ...prev,
      placeId: '',
      description: e.target.value,
      formattedAddress: '',
    }));
    if (abortController) {
      abortController.abort();
    }
    fetchLocations(e.target.value);
  };

  const fetchLocations = async (locationQuery: string, selectFirst: boolean = false) => {
    try {
      abortController = new AbortController();
      const { data } = await axiosInstance<PlaceObjectV2[]>({
        signal: abortController.signal,
        url: LOCATION_LIST_API,
        params: {
          s_token: secureToken,
          q: locationQuery,
          lang,
        },
      });
      selectFirst ? handleSelectPlace(data[0]) : setLocations(data);
    } catch (error: any) {
      if (error?.code !== 'ERR_CANCELED') console.error(error);
      setLocations([]);
    }
  };

  const handleSelectPlace = (locationObject: PlaceObjectV2) => {
    setSearchPlace((prev) => ({
      ...prev,
      ...locationObject,
    }));
    setShowError(false);
    setLocations([]);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (locations.length === 0 && e.key == 'Enter') {
      goToResult();
    } else {
      switch (e.key) {
        case 'ArrowUp':
          setSelectedLocationIndex((prevIndex) =>
            prevIndex !== null ? (prevIndex > 0 ? prevIndex - 1 : locations.length - 1) : locations.length - 1,
          );
          break;
        case 'ArrowDown':
          setSelectedLocationIndex((prevIndex) =>
            prevIndex !== null ? (prevIndex < locations.length - 1 ? prevIndex + 1 : 0) : 0,
          );
          break;
        case 'Escape':
          onClose();
          break;
        case 'Enter':
          if (selectedLocationIndex !== null) {
            handleSelectPlace(locations[selectedLocationIndex]);
          } else {
            handleSelectPlace(locations[0]);
          }
          break;
        default:
          break;
      }
    }
  };

  const clearSearch = () => {
    abortController?.abort();
    setSearchPlace({
      placeId: '',
      description: '',
      formattedAddress: '',
    });
    setShowError(false);
    inputRef.current?.focus();
    setLocations([]);
  };

  useEffect(() => {
    if (isMobileView && !isInitialData && searchPlace.placeId !== '') {
      goToResult();
    }
  }, [goToResult, searchPlace, isMobileView]);

  useEffect(() => {
    setSearchPlace((prev) => ({ ...prev, description: searchParams.get('place.description') || '' }));
  }, [searchParams]);

  return (
    <div
      ref={searchRef}
      className={`search-container fo-border-sm rounded-2 border-grey-200 left-0 p-4 `}
      onClick={(e) => e.stopPropagation()}
    >
      <Button
        variant="primaryGhost"
        role="button"
        extraClasses="ps-0 my-4 d-md-none justify-content-start"
        onClick={onClose}
      >
        <SVGIcon
          iconType={'long-arrow-left'}
          className="me-2"
        />
        <span>{t('global.btns.back')}</span>
      </Button>

      <div
        className={`${extraClasses} full-search-form w-100 bg-white rounded-3 ps-4 pt-1 p-2 fo-border-md border-primary`}
      >
        <div className={`search-form d-flex justify-content-between align-items-center`}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <div className="flex-grow-1 position-relative">
              <label
                htmlFor={`location-criteria- `}
                className="caption -medium color-grey-500 w-100  mb-0"
              >
                {t('officeSearch.searchWidget.location.label')}
              </label>

              <input
                ref={inputRef}
                value={searchPlace?.description}
                className={`-medium h-100 w-100 -remove-border -regular p-0 text-truncate`}
                id={`location-criteria- `}
                name={'name'}
                type={'text'}
                autoComplete="off"
                onChange={getLocationsList}
                placeholder={`${t('officeSearch.searchWidget.location.placeholder')}`}
                onKeyUp={handleKeyDown}
              />
            </div>
            {!!searchPlace?.description && (
              <Button
                variant="tertiaryGhost"
                extraClasses="me-2 ms-1"
                size="xs"
                onClick={clearSearch}
                isIconButton={true}
              >
                <SVGIcon
                  iconType="cross"
                  width="16"
                  height="16"
                />
              </Button>
            )}
            {showError && (
              <div className="me-2">
                <SVGIcon
                  iconType="alert-circle"
                  fill="#dc3545"
                />
              </div>
            )}

            <SVGIcon
              iconType="search"
              className="d-md-none flex-shrink-0"
            />
          </div>
          <Button
            extraClasses="d-none d-md-inline-flex"
            variant="accent"
            onClick={() => {
              locations.length > 0 && handleSelectPlace(locations[0]);
              goToResult();
            }}
          >
            <span className="me-2">{t('global.btns.search')}</span>
            <SVGIcon iconType={'search'} />
          </Button>
        </div>
      </div>
      <div className="mt-1">
        {showError && (
          <span className="text-2 -regular text-danger">{t('officeSearch.searchWidget.location.labelError')} </span>
        )}
      </div>

      {locations.length > 0 && (
        <div className="mt-4">
          <ul className="list-group list-group-flush">
            {locations.map((location, index) => (
              <LocationItem
                location={location}
                handleSelectPlace={(location) => {
                  handleSelectPlace(location);
                }}
                key={location.placeId}
                isSelected={selectedLocationIndex === index}
              />
            ))}
          </ul>
        </div>
      )}

      <div className={`collapse ${showSuggestions ? 'show' : ''} mt-3`}>
        <span className="fo-display-4 -medium">{t('officeSearch.searchWidget.help.title')}</span>
        <p className="color-grey-500 -regular">{t('officeSearch.searchWidget.help.description')}</p>
        {QUICK_LINKS.map((seoLink, i) => (
          <ButtonLink
            key={seoLink.label.fr}
            size={`${isMobileView ? 'sm' : 'md'}`}
            to={`/${lang}/${lang == 'fr' ? 'bureaux' : 'offices'}/${seoLink.link}?quicklink=${
              seoLink.label[lang]
            }&${getPersistedSearchParams(searchParams)}`}
            onClick={() => {
              mixpanelTrack(`C.search_true_ql${i + 1}`);
              onSuccess(seoLink.label[lang]);
            }}
            extraClasses="me-3 mt-3"
            variant={'primaryOutline'}
          >
            <span>{seoLink.label[lang]}</span>
          </ButtonLink>
        ))}
      </div>
    </div>
  );
};

export default SearchForm;
