export type SeoLink = { link: string; label: { en: string; fr: string } };

const CORE_LINKS: SeoLink[] = [
  {
    link: 'paris/75001',
    label: {
      fr: 'Paris 1er',
      en: 'Paris 1st',
    },
  },
  {
    link: 'paris/75002',
    label: {
      fr: 'Paris 2ème',
      en: 'Paris 2nd',
    },
  },
  {
    link: 'paris/75003',
    label: {
      fr: 'Paris 3ème',
      en: 'Paris 3rd',
    },
  },
  {
    link: 'paris/75008',
    label: {
      fr: 'Paris 8ème',
      en: 'Paris 8th',
    },
  },
  {
    link: 'paris/75009',
    label: {
      fr: 'Paris 9ème',
      en: 'Paris 9th',
    },
  },
  {
    link: 'paris/75010',
    label: {
      fr: 'Paris 10ème',
      en: 'Paris 10th',
    },
  },
  {
    link: 'paris/75011',
    label: {
      fr: 'Paris 11ème',
      en: 'Paris 11th',
    },
  },
  {
    link: 'paris/75017',
    label: {
      fr: 'Paris 17ème',
      en: 'Paris 17th',
    },
  },
];

const ADDITIONAL_FOOTER_LINKS: SeoLink[] = [
  {
    link: 'paris/75002/Sentier',
    label: {
      fr: 'Paris, Sentier',
      en: 'Paris, Sentier',
    },
  },
  {
    link: 'paris/75004',
    label: {
      fr: 'Paris 4ème',
      en: 'Paris 4th',
    },
  },
  {
    link: 'paris/75010/republique',
    label: {
      fr: 'Paris, République',
      en: 'Paris, République',
    },
  },
  {
    link: 'paris/75009/Saint-Lazare',
    label: {
      fr: 'Paris, Saint-Lazare',
      en: 'Paris, Saint-Lazare',
    },
  },
  {
    link: 'paris/75009/opera',
    label: {
      fr: 'Paris, Opéra',
      en: 'Paris, Opéra',
    },
  },
  {
    link: 'paris/75008/Europe',
    label: {
      fr: 'Paris, Europe',
      en: 'Paris, Europe',
    },
  },
  {
    link: 'paris/75003/Le-Marais',
    label: {
      fr: 'Paris, Le Marais',
      en: 'Paris, Le Marais',
    },
  },
  {
    link: 'paris/75006',
    label: {
      fr: 'Paris 6ème',
      en: 'Paris 6th',
    },
  },
  {
    link: 'paris/75015/Montparnasse',
    label: {
      fr: 'Paris, Montparnasse',
      en: 'Paris, Montparnasse',
    },
  },
  {
    link: 'paris/75011/Bastille',
    label: {
      fr: 'Paris, Bastille',
      en: 'Paris, Bastille',
    },
  },
  {
    link: 'paris/75008/champs-elysees',
    label: {
      fr: 'Paris, Champs-Élysées',
      en: 'Paris, Champs-Élysées',
    },
  },
  {
    link: 'neuilly-sur-seine',
    label: {
      fr: 'Neuilly-sur-Seine',
      en: 'Neuilly-sur-Seine',
    },
  },
  {
    link: 'lyon',
    label: {
      fr: 'Lyon',
      en: 'Lyon',
    },
  },
  {
    link: 'luxembourg',
    label: {
      fr: 'Luxembourg',
      en: 'Luxembourg',
    },
  },
];

const ADDITIONAL_QUICK_LINKS: SeoLink[] = [
  {
    link: 'neuilly-sur-seine',
    label: {
      fr: 'Neuilly-sur-Seine',
      en: 'Neuilly-sur-Seine',
    },
  },
];

export const QUICK_LINKS: SeoLink[] = [...CORE_LINKS, ...ADDITIONAL_QUICK_LINKS];
export const SEO_LINKS_DATA: SeoLink[] = [...CORE_LINKS, ...ADDITIONAL_FOOTER_LINKS];
