import GenericModal from '~/components/common/GenericModal';
import AccountLinks from '../userMenu/AccountLinks';
import { useTranslation } from 'react-i18next';

const AccountModal = () => {
  const { t } = useTranslation();
  return (
    <GenericModal
      open={true}
      title={t('navbar.user.myAccount.label').toString()}
      id="userAccountModal"
    >
      <div className="modal-body p-0">
        <AccountLinks />
      </div>
    </GenericModal>
  );
};

export default AccountModal;
