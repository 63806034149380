// import { useAppContext } from '@/app/context/useAppContext';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import AccountModalBtn from './AccountModalBtn';
import { useTranslation } from 'react-i18next';
import { useLanguageParam } from '~/config/i18n';

const HostButtons = () => {
  const { t } = useTranslation();
  const locale = useLanguageParam();

  return (
    <>
      <div className="flex-basis-100 d-flex flex-row align-items-center">
        <a
          className="bottom-nav-button d-flex flex-column align-items-center color-grey-300 text-decoration-none mx-auto px-0"
          href={`/${locale}/dashboard/host/overview`}
        >
          <SVGIcon iconType={'building'} />
          <div className="overline">{t('navbar.user.myOffices.label')}</div>
        </a>
      </div>

      <div className="flex-basis-100 d-flex flex-row align-items-center">
        <a
          className="bottom-nav-button d-flex flex-column align-items-center color-grey-300 text-decoration-none mx-auto px-0"
          href={`/${locale}/dashboard/conversations`}
        >
          <SVGIcon iconType={'inbox'} />
          <div className="overline">{t('header.myRequests.label')}</div>
        </a>
      </div>

      <div className="flex-basis-100 d-flex flex-row align-items-center">
        <div className="bottom-nav-button mx-auto px-0">
          <AccountModalBtn />
        </div>
      </div>
    </>
  );
};

export default HostButtons;
