import { useTranslation } from 'react-i18next';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import { useUser } from '~/modules/useUser';
import LogoutButton from '../LogoutButton';
import AccountLinkItem from './AccountLinkItem';
import { useLanguageParam } from '~/config/i18n';

const HostLinks = () => {
  const { t } = useTranslation();
  const clientStore = useUser();

  const locale = useLanguageParam();
  return (
    <>
      <div className="list-group">
        <AccountLinkItem
          link={`/${locale}/dashboard/conversations`}
          linkText={t('header.myRequests.label')}
          iconClass="inbox"
        />

        <AccountLinkItem
          link={`/${locale}/dashboard/host/overview`}
          linkText={t('navbar.user.myOffices.label')}
          iconClass="building"
        />

        {clientStore?.showDocuments && (
          <AccountLinkItem
            link={`/${locale}/dashboard/documents/contracts`}
            linkText={t('navbar.user.myDocuments.label')}
            iconClass="document"
          />
        )}

        <div className="w-100 fo-border-bottom-md border-1 border-grey-200 my-2"></div>

        <AccountLinkItem
          link={t('navbar.user.host.getHelp.url')}
          linkText={t('navbar.user.getHelp.label')}
          iconClass="help"
          newTab={true}
        />

        <LogoutButton />

        <div className="px-4 py-4">
          <a
            className="btn btn-sm btn-fo-outline-primary d-flex justify-content-center"
            href={`/${locale}/create-office-wizard`}
          >
            <SVGIcon
              iconType={'plus'}
              className="me-2"
            />
            <span>{t('global.btns.createOffice')}</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default HostLinks;
