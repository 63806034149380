import { useSearchParams } from '@remix-run/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import SearchForm from './SearchForm';
import mixpanelTrack from '~/hooks/useMixPanel';
import { Button } from '../buttons/Button';
import useWindowDimensions from '~/hooks/useWindowDimensions';

const MinimalSearch = ({ isHero = false }: { isHero?: boolean }) => {
  const { t } = useTranslation();
  const { width } = (useWindowDimensions() || 769) as { width: number };
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const [placeholder, setPlaceholder] = useState(searchParams.get('quicklink') || '');

  const closeSearch = () => {
    setIsOpen(false);
    if (document.body.classList.contains('overflow-hidden')) document.body.classList.remove('overflow-hidden');
  };

  return (
    <div className="d-flex justify-content-center align-items-center ">
      <div
        className={`min-search-form bg-white fo-border-sm border-grey-200 position-relative search-form-shadow rounded-3 w-100 d-flex justify-content-between ps-4 pe-2 py-2 ${
          isHero ? 'min-search-hero ps-4' : ''
        } align-items-center`}
        role="button"
        onClick={() => {
          mixpanelTrack('C.search_intent');
          setIsOpen(true);
        }}
      >
        <span className={`text-1 me-2 -medium text-truncate color-primary`}>
          {placeholder || searchParams.get('place.description') || t('officeSearch.searchWidget.location.label')}
        </span>

        <Button
          variant={'accent'}
          size="sm"
          extraClasses={`d-none d-md-block search-btn`}
          aria-label="Search"
          name="search"
        >
          <span className={`d-none d-lg-inline -medium mx-2`}>{t('officeSearch.searchWidget.cta')}</span>
          <SVGIcon
            iconType={'search'}
            width={'24'}
            height={'24'}
          />
        </Button>

        <SVGIcon
          className="d-md-none flex-shrink-0"
          iconType={'search'}
          width={'24'}
          height={'24'}
        />

        {isOpen && (
          <SearchForm
            onClose={closeSearch}
            onSuccess={(label: string) => {
              closeSearch();
              setPlaceholder(label);
            }}
            isMobileView={width < 769}
          />
        )}
      </div>
    </div>
  );
};

export default MinimalSearch;
