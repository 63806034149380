import { Button } from '~/components/common/buttons/Button';
import { useUser } from '~/modules/useUser';
import AccountLinkList from './AccountLinks';

const AccountDropdown = () => {
  const clientStore = useUser();

  return (
    <div className="dropdown">
      <Button
        extraClasses="btn-icon rounded-pill -initials"
        dataAttributes={{ 'data-bs-toggle': 'dropdown' }}
        ariaAttributes={{ 'aria-expanded': 'false' }}
      >
        <div className="caption">{clientStore?.initials?.charAt(0)}</div>
      </Button>
      <div
        className="dropdown-menu dropdown-menu-end mt-3"
        style={{ width: '303px' }}
      >
        <AccountLinkList />
      </div>
    </div>
  );
};

export default AccountDropdown;
