import { NavLink } from '@remix-run/react';
import { useRef } from 'react';
import { MinimalSearch } from '~/components/common/search';
import UserMenu from './userMenu/UserMenu';
import { useLanguageParam } from '~/config/i18n';

export type Configuration = {
  showDesktopSearch: boolean;
  showMobileSearch: boolean;
  showMobileHeader: boolean;
  onHero?: boolean;
  showBottomNav: boolean;
  narrowContentWidth?: boolean;
};

const TopMenu = ({ configuration }: { configuration: Configuration }) => {
  const lang = useLanguageParam();

  const navbarBrandRef = useRef<HTMLAnchorElement>(null);
  const spacerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        className={`${
          configuration.showMobileHeader || configuration.showMobileSearch
            ? 'sticky-top'
            : 'sticky-md-top d-none d-md-block'
        } ${configuration.showMobileHeader && configuration.showMobileSearch ? 'sticky-search' : ''}`}
      >
        <nav className={`navbar ${!configuration.onHero && '-with-border'} py-0 py-md-5`}>
          <div
            className={`${
              configuration.narrowContentWidth ? 'container fixed-container-md' : 'container-fluid'
            }  flex-wrap flex-md-nowrap`}
          >
            <>
              <NavLink
                className={`navbar-brand py-4 py-md-0 ${configuration.showMobileHeader ? '' : 'd-none d-md-flex'}`}
                to={`/${lang}`}
                ref={navbarBrandRef}
                data-turbo="false"
              >
                <img
                  src="/media/logotype.svg"
                  alt="Fiveoffices"
                  className={`fo-logo-full d-md-none d-lg-block`}
                />
                <img
                  src="/media/logo-fiveoffices-symbol.svg"
                  alt="Fiveoffices"
                  className={`fo-logo-min d-none d-md-block d-lg-none`}
                />
              </NavLink>
              {!configuration.onHero && (
                <div
                  className="navbar-spacer"
                  ref={spacerRef}
                />
              )}
            </>

            <div
              className={`search-form-wrapper me-md-6 ${configuration.showMobileSearch ? '' : 'd-none d-md-block'} ${
                configuration.showDesktopSearch ? '' : 'd-md-none'
              } `}
              // className={`search-form-wrapper ${showSearch ? '' : 'd-none'}`}
              // ref={searchFormRef}
            >
              <MinimalSearch />
            </div>

            <UserMenu extraClasses={configuration.showMobileHeader ? '' : 'd-none d-md-flex'} />
          </div>
        </nav>
      </div>
    </>
  );
};

export default TopMenu;
